import { gql } from 'graphql-tag'
import { seoFragment } from '../fragments/seo.js'
import { responsiveImageFragment } from '../fragments/images'

export const servizioPage = gql`
  ${seoFragment}
  ${responsiveImageFragment}
  query ServizioPage($locale: SiteLocale, $slug: String) {
    listaServizi(locale: $locale, filter: { slug: { eq: $slug } }) {
      seo {
        ...SeoFragment
      }
      _allSlugLocales {
        value
        locale
      }
      titolo
      slug
      body {
        ... on BloccoImmagineTesto1Record {
          id
          landscape: immagine {
            responsiveImage(imgixParams: { fm: webp, q: "50" }) {
              ...ResponsiveImageFragment
            }
          }
          portrait: immagine {
            responsiveImage(
              imgixParams: { auto: compress, q: "50", ar: "1:1", fit: crop }
            ) {
              ...ResponsiveImageFragment
            }
          }
          testo
          titolo
        }
        ... on IntroPagineRecord {
          id
          testoSinistra
          testoDestra
          titolo
        }
        ... on PrimoBloccoContenutiRecord {
          id
          titolo
          testo
        }
      }
      bloccoSovrapposto {
        titoloSinistra
        testoDestra
        cta {
          testo
          link
        }
      }
      ambitiDiIntervento {
        titolo
        slug
      }
      servizioSuggerito {
        titolo
        testo
        cta {
          testo
          link
        }
      }
      testoBloccoSettori
    }
  }
`

export const serviziMenuNav = gql`
  query ServiziMenuNav($locale: SiteLocale) {
    allListaServizis(locale: $locale) {
      _allSlugLocales {
        value
        locale
      }
      slug
      titolo
      id
      suddivisioneServizioMenu
    }
  }
`

export const serviziData = gql`
  ${seoFragment}
  query ServiziData($locale: SiteLocale) {
    servizi(locale: $locale) {
      seo {
        ...SeoFragment
      }
      titolo
      slug
      intro {
        testoSinistra
        testoDestra
        titolo
      }
    }
    allListaServizis(locale: $locale) {
      seo {
        ...SeoFragment
      }
      _allSlugLocales {
        value
        locale
      }
      slug
      titolo
      id
      suddivisioneServizioMenu
    }
    allListaAmbitis(locale: $locale) {
      _allSlugLocales {
        value
        locale
      }
      slug
      titolo
    }
  }
`
